/* global dataLayer */
import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledBanner = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap:10px;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    transition: transform 0.3s ease;
    transform: ${({ $show }) => ($show ? 'translateY(0)' : 'translateY(100%)')};
    z-index: 9999999999;
    background: rgba(0, 0, 0, 0.37);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.8px);
    -webkit-backdrop-filter: blur(6.8px);

`;

const CloseDiv = styled.div`
    position: relative;
    right: 0;
    margin-right: 25px;
    display: flex;
    gap: 10px;
  
    @media screen and (max-width: 12in) {
       margin:0;
    }
`;

const StyledMuiButton = styled(Button)`
    color: rgb(210, 210, 210) !important;
    font-weight: bold !important;
    border: 1px solid #8800ff !important;
    background: #2f0e58 !important;
    margin-top:auto !important;
    font-family: "Lexend Deca", "Oxygen" !important;
`;

const CookieBanner = ({ show }) => {
    const [showBanner, setShowBanner] = useState(show);

    const handleClose = () => {
        setShowBanner(false);
    };

    const handleAccept = () => {
        localStorage.setItem('michaelsCookiesAccepted', 'all');
        const script = document.createElement('script');
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-5VQV16CSTY";
        document.head.append(script);
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'G-5VQV16CSTY');
        handleClose();
    };

    const handleRefuse = () => {
        sessionStorage.setItem('michaelsCookiesRefused', 'true');
        handleClose();
    }

    return (
        <StyledBanner $show={showBanner}>
            <IconButton name='close-cookies' style={{ position: 'fixed', right: 0, top: 0 }} onClick={handleClose}>
                <CloseIcon style={{ color: 'white' }} />
            </IconButton>
            <div style={{ marginLeft: '20px' }}>
            This website uses technical cookies, which are strictly necessary for its proper functioning and, with your consent, cookies to improve the website's performance and your browsing experience. 
            These cookies can be first-party (i.e., managed directly by Michael Lavigna) or third-party cookies. You can choose to accept all cookies by clicking the "Accept all cookies" button. 
            You can also proceed with browsing without cookies or other tracking tools other than technical cookies by closing this banner, clicking the "Reject all" button.
            </div>
            <div>
                <CloseDiv>
                    <StyledMuiButton name='accept-cookies' variant='outlined' size="medium" onClick={handleAccept}>Accept all cookies</StyledMuiButton>
                    <StyledMuiButton name='decline-cookies' variant='outlined' size="medium" onClick={handleRefuse}>Reject third party cookies</StyledMuiButton>
                </CloseDiv>
            </div>
        </StyledBanner>
    );
};

export default CookieBanner;
