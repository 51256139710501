/* global dataLayer */
import { useEffect } from "react";

const CheckForCookies = () => {
    useEffect(() => {
        const cleanupLocalStorage = () => {
            const cookiesAcceptedDate = localStorage.getItem('michaelsCookiesAcceptedDate');

            if (cookiesAcceptedDate) {
                const oneMonthInMillis = 30 * 24 * 60 * 60 * 1000;
                const savedDate = new Date(cookiesAcceptedDate).getTime();
                const currentDate = new Date().getTime();

                if (currentDate - savedDate >= oneMonthInMillis) {
                    localStorage.removeItem('michaelsCookiesAccepted');
                    localStorage.removeItem('michaelsCookiesAcceptedDate');
                }
            }
        };

        cleanupLocalStorage();
    }, []);

    useEffect(() => {
        const checkAnalytics = () => {
            if (localStorage.getItem("michaelsCookiesAccepted") === "all") {
                const script = document.createElement("script");
                script.async = true;
                script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_KEY}`;
                document.head.appendChild(script);

                window.dataLayer = window.dataLayer || [];
                function gtag() { window.dataLayer.push(arguments); }
                window.gtag = gtag;

                gtag('js', new Date());
                gtag('config', process.env.REACT_APP_GA_KEY);
            }
        };

        checkAnalytics();
    }, []);
    
    return null;
}

export default CheckForCookies;
