import styled, { keyframes } from "styled-components";
import { Button } from "@mui/material";

const rotate = keyframes`
0% {
    transform: scale(1);
}
50% {
    transform: scale(1.2);
}
100% {
    transform: scale(1);
}
`;
export const StyledLoaderWrapper = styled.div`
position: fixed;
top: 0;
left: 0;
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
width: 100vw;
background: #9d9d9dc7;
z-index: 999999999;
overflow: hidden;`;

export const StyledSvgCodeLogo = styled.svg`
width: 100px;
height: 100px;
animation: ${rotate} 2s linear infinite;`;
export const StyledNFDiv = styled.div`
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;`;

export const StyledButton = styled(Button)`
color: rgb(210, 210, 210) !important;
font-weight: bold !important;
border: 1px solid #8800ff !important;
background: #2f0e58 !important;
margin-top:auto !important;
width: 90% !important;
font-family: "Lexend Deca", "Oxygen" !important;
    &:hover{
        background: #481b4a !important;
    }`;

export const StyledButtonContainer = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:center;
margin:3vh 0 5vh 0!important;`;

export const StyledLangCardSection = styled.div`
margin-top:4vh;
margin-bottom:4vh;`;
export const StyledLangCardsContainer = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: 1fr;
gap: 0px;

    & div{
    text-align:center;
            & div>svg {
                width: 30%;
                height: auto;
                object-fit: cover;
            }
    }`;
export const StyledInnerCertContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 2vh;
margin-bottom: 3vh;`;
export const StyledGITH1 = styled.h1`
text-align:center;
margin-top: calc(var(--nav-height) + 8vh);`;
export const StyledGITSection = styled.div`
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: 1fr;
gap: 2vw;
grid-row-gap: 0px;
padding: 0 1% 1% 1%;
    & > div {
        padding: 1%;
    }

    & > div:first-child {
        grid-column: 1 / 2;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 2vh;
    }

    & > div:nth-child(2) {
        grid-column: 2 / 4;
        display: flex;
        flex-direction: column;
        justify-content: start;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
  }`;
export const StyledContactDiv = styled.div`
display: flex;
flex-direction: column;
gap: 2vh;`;
export const StyledAccountsContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: baseline;`;
export const StyledInfoContainer = styled.div`
display: flex;
flex-direction: row-reverse;
gap: 20px;`;

export const StyledContactButton = styled(Button)`
color: rgb(210, 210, 210) !important;
font-weight: bold !important;
border: 1px solid #8800ff !important;
background: #2f0e58 !important;
margin:3vh auto !important;
width: 90% !important;
font-family: "Lexend Deca", "Oxygen" !important;
    &:hover{
        background: #481b4a !important;
    }`;
