import { useEffect, useState } from "react";
import './Navbar.css';
import NavCodeLogo from "../../Helpers/NavCodeLogo";
import NavLightMode from "../../Helpers/NavLightMode";
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

export default function Navbar() {
    const [selected, setSelected] = useState();
    const [iconColor, setIconColor] = useState();
    const [isExpanded, setIsExpanded] = useState(false);
    const location = useLocation();

    const handleColorChangeEnter = () => {
        setIconColor('#0B65C2');
    };
    const handleColorChangeLeave = () => {
        setIconColor();
    };

    useEffect(() => {
        const navLinks = document.querySelectorAll(".nav-link");
        navLinks.forEach(link => {
            link.getAttribute('id') === selected ? link.classList.add('selected') : link.classList.remove('selected');
        });
    }, [selected]);

    useEffect(() => {
        const currentPath = location.pathname;
        setSelected(currentPath === "/" ? 'home' : currentPath.substring(1));

        setIsExpanded(false);
    }, [location.pathname]);


    const toggleMenu = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <div className={`navbar-container ${isExpanded ? 'expanded' : ''}`}>
                <NavCodeLogo width='56' />
                <div className={`nav-toggle ${isExpanded ? 'open' : ''}`} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <Link className="nav-link" id="linked-in" to="https://www.linkedin.com/in/hi-im-michael" target="_blank"><FontAwesomeIcon size="xl" style={{ color: iconColor }} onMouseEnter={handleColorChangeEnter} onMouseLeave={handleColorChangeLeave} icon={faLinkedin} /></Link>
                <Link className="nav-link" id="home" to="/">Home</Link>
                <Link className="nav-link" id="about-me" to="/about-me">About Me</Link>
                <Link className="nav-link" id="contact-me" to="/contact-me">Get in touch</Link>
                <NavLightMode />
            </div>
        </>
    );
}
