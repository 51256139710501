import { lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navbar from '../Views/Navbar/Navbar';
import CookieBanner from '../Helpers/CookieBanner';
import GoToTop from '../Helpers/GoToTop';
import Loader from '../Loader/Loader';

const Home = lazy(() => import('../Views/Home/Home'));
const AboutMe = lazy(() => import('../Views/AboutMe/AboutMe'));
const GetInTouch = lazy(() => import('../Views/GetInTouch/GetInTouch'));
const NotFound = lazy(() => import('../Views/NotFound/NotFound'));

const AppRoutes = () =>{
    const location = useLocation();

    const isNotFoundRoute = location.pathname !== '/'
        && location.pathname !== '/about-me'
        && location.pathname !== '/contact-me'

    return (
        <>
            {!isNotFoundRoute && <Navbar />}
            <Suspense fallback={<Loader/>}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about-me" element={<AboutMe />} />
                    <Route path="/contact-me" element={<GetInTouch />} />
                    {isNotFoundRoute && <Route path="*" element={<NotFound />} />}
                </Routes>
            </Suspense>
            {!isNotFoundRoute && <GoToTop />}
            {/* {!isNotFoundRoute && <Footer />} */}
            {!isNotFoundRoute && <CookieBanner show={localStorage.getItem('michaelsCookiesAccepted') || sessionStorage.getItem('michaelsCookiesRefused') ? false : true} />}
        </>
    );
}

export default AppRoutes;