import { useNavigate } from "react-router-dom";

const NavCodeLogo = ({ width }) => {
    const navigate = useNavigate(); // Muovi l'uso di useNavigate all'interno del componente

    return (
        <>
            <div className="nav-logo">
                <svg onClick={() => navigate('/')} className="svg-code-logo" width={width} viewBox="0 0 24 24">
                    <g fill="none">
                        <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z" />
                        <path fill="#8800ff" d="M13.476 3.629a1.5 1.5 0 0 1 1.217 1.738l-2.431 13.787a1.5 1.5 0 1 1-2.955-.521l2.431-13.787a1.5 1.5 0 0 1 1.738-1.217ZM7.561 6.697a1.5 1.5 0 0 1 0 2.12L4.379 12l3.182 3.182a1.5 1.5 0 1 1-2.122 2.121L1.197 13.06a1.5 1.5 0 0 1 0-2.12l4.242-4.243a1.5 1.5 0 0 1 2.122 0Zm11 0 4.242 4.242a1.5 1.5 0 0 1 0 2.121l-4.242 4.243a1.5 1.5 0 1 1-2.122-2.121L19.621 12 16.44 8.818a1.5 1.5 0 1 1 2.122-2.121Z" />
                    </g>
                </svg>
                <p>Michael Lavigna</p>
            </div>
        </>
    );
};

export default NavCodeLogo;
