import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './Components/AppRoutes/AppRoutes';
import CheckForCookies from './Components/Helpers/CheckForCookies';
function App() {
  return (
    <Router>
       <CheckForCookies />
      <AppRoutes />
    </Router>
  );
}


export default App;
