import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Fab from '@mui/material/Fab';

import styled from 'styled-components';

const StyledButtonUp = styled(Fab)`
  position: fixed !important;
  bottom: 5% !important;
  right: 20px !important;
  background: #8800ff !important;
  cursor: pointer !important;
  transition: 0.3s ease !important;
  opacity: ${({ $isVisible }) => ($isVisible ? '1' : '0')} !important;
`;

export default function GoToTop() {
    const [isVisible, setIsVisible] = useState(false);
    const [scrollingNav, setScrollingNav] = useState(false);


    useEffect(() => {
        if (scrollingNav) {
            document.querySelector('.navbar-container').classList.add('scrolling');
        } else {
            document.querySelector('.navbar-container').classList.remove('scrolling');
        }
        return;
    }, [scrollingNav]);


    useEffect(() => {
        let lastScrollTop = 0;
        const toggleVisibility = () => {
            if (window.pageYOffset > 200 || window.scrollY > 200) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
            let st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop) {
                document.querySelector('.navbar-container').style.top = '-200px';
            } else {
                document.querySelector('.navbar-container').style.top = '0';
            }

            lastScrollTop = st <= 0 ? 0 : st;

            if (window.pageYOffset > 40 || window.scrollY > 40) {
                setScrollingNav(true);
            } else {
                setScrollingNav(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <StyledButtonUp name='go-to-top' size="medium" $isVisible={isVisible} onClick={scrollToTop}>
                <FontAwesomeIcon icon={faArrowUp} size="xl" style={{ color: '#161631' }} />
            </StyledButtonUp>
        </>
    );
};
